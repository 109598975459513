import * as React from "react";
import { TypeAnimation } from "react-type-animation";
import { Link } from "gatsby";
import type { HeadFC } from "gatsby";
import { shuffleArray } from "../util";
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer/footer";
import InboxForm from "../components/inboxform/inboxform";
import Suggestion from "../components/suggestion/suggestion";
import SpecialOffer from "../components/specialoffer/specialoffer";
import GreatIdea from "../components/rail/greatidea";
import Statistics from "../components/statistics/statistics";
import CLI from "../components/cli/cli";
import laptop from "../images/laptop.svg";
import logomark from "../images/logomark.svg";
import phone from "../images/phone.png";

const IndexPage = () => {
    const animationArray = shuffleArray([
        "sites that sell your data.",
        "an app you'll only use once.",
        "chatty ecommerce stores.",
        "shady newsletter writers.",
        "social media notifications.",
        "website security breaches.",
        "filling up your real inbox."
    ])
        .map((str, idx) => [str, 3000 + 200 * idx])
        .flat();
    return (
        <div className={"overflow-x-hidden"}>
            <Navbar />
            <main className={"relative z-0"}>
                <div
                    className={
                        "relative z-10 px-4 py-8 pb-0 md:container md:mx-auto md:flex md:items-center md:py-0 md:pb-16 md:pb-0 lg:px-8 lg:pb-16"
                    }>
                    <div
                        className={
                            "pt-16 text-center text-stone-800 dark:text-stone-300 md:w-[65%] md:flex-1 md:pt-0 md:text-left lg:pb-12"
                        }>
                        <h1
                            className={
                                "inline-block bg-gradient-to-r from-pink-500 to-rose-500 bg-clip-text text-4xl font-bold text-transparent md:text-5xl lg:text-7xl"
                            }>
                            Stop spam
                        </h1>
                        <span className={"inline-block text-4xl font-bold md:text-5xl lg:text-7xl"}>&nbsp;from</span>
                        <div
                            className={
                                "mt-2 h-20 overflow-hidden text-center text-3xl font-bold md:text-left lg:h-24 lg:text-5xl"
                            }>
                            <TypeAnimation
                                sequence={animationArray}
                                deletionSpeed={80}
                                cursor={false}
                                className={"type"}
                                repeat={100}
                            />
                        </div>
                        <h2
                            className={
                                "inline-block text-xl font-medium dark:text-stone-50/75 md:pr-8 lg:pt-8 lg:pr-0 lg:pb-20"
                            }>
                            Maildrop is a{" "}
                            <span
                                className={
                                    "inline-block bg-gradient-to-r from-pink-500 to-rose-500 bg-clip-text font-bold text-transparent"
                                }>
                                free
                            </span>{" "}
                            disposable email address to use anytime.
                        </h2>
                    </div>
                    <div className={"flex justify-center pt-16 md:w-[35%] md:flex-none"}>
                        <img
                            data-cypress={"laptop"}
                            src={laptop}
                            className={"h-64 md:h-[35vh] md:pt-16 lg:min-h-[calc(100vh-18rem)] lg:pt-0"}
                            alt={"woman sitting at a laptop"}
                        />
                    </div>
                </div>
                <div className={"absolute bottom-0 z-0 w-full translate-y-1 text-stone-200 dark:text-stone-800"}>
                    <svg
                        viewBox="0 0 960 540"
                        className={"w-full"}
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1">
                        <path
                            d="M0 399L40 398.8C80 398.7 160 398.3 240 405.5C320 412.7 400 427.3 480 418.5C560 409.7 640 377.3 720 368C800 358.7 880 372.3 920 379.2L960 386L960 541L920 541C880 541 800 541 720 541C640 541 560 541 480 541C400 541 320 541 240 541C160 541 80 541 40 541L0 541Z"
                            fill="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="miter"></path>
                    </svg>
                </div>
            </main>
            <section
                className={
                    "relative z-0 bg-gradient-to-b from-stone-200 to-stone-100 dark:from-stone-800 dark:to-stone-900"
                }>
                <div className={"relative z-10 px-8 pt-16 pb-24 md:container md:mx-auto md:pt-0 lg:px-8"}>
                    <div className={"subhead"}>Get started for free</div>
                    <div className={"md:flex md:items-start"}>
                        <div className={"pb-16 md:w-1/2 md:flex-none md:pr-8 md:pb-32 lg:pr-16"}>
                            <div className={"pb-4 text-2xl font-bold"}>1. Make up your own email address.</div>
                            <p className={"max-w-[50ch] pb-8 dark:text-stone-50/75"}>
                                No signup required &mdash; Maildrop is free for anyone to use when you need a quick,
                                disposable email address.
                            </p>
                            <div>
                                <InboxForm size={"main"} />
                            </div>
                        </div>
                        <div className={"pb-32 md:flex md:w-1/2 md:flex-none md:justify-end md:pb-0"}>
                            <div className={"lg:w-[70%]"}>
                                <div className={"pt-1 text-center text-lg font-bold"}>
                                    Want a suggestion? How about...
                                </div>
                                <div className={"text-center dark:text-stone-50/75"}>
                                    <Suggestion />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"md:flex md:items-center"}>
                        <div className={"md:w-1/2 md:flex-none md:pr-8 lg:pr-16"}>
                            <div className={"pb-32"}>
                                <div className={"pb-4 text-2xl font-bold"}>
                                    2. Give out the Maildrop address instead of your real email address.
                                </div>
                                <p className={"max-w-[50ch] dark:text-stone-50/75"}>
                                    Your <span className={"dark:text-stone-50"}>maildrop.cc</span> email address is
                                    accepted everywhere &mdash; websites, apps, ecommerce stores, you name it.
                                </p>
                            </div>
                            <div>
                                <div className={"pb-4 text-2xl font-bold"}>
                                    3. Check your Maildrop inbox when you need.
                                </div>
                                <p className={"max-w-[50ch] pb-8 dark:text-stone-50/75"}>
                                    Within a few seconds, you can check your Maildrop inbox and find the message you're
                                    looking for. The sender will never send your real email address any messages.
                                </p>
                                <Link
                                    to={"/how-it-works/"}
                                    className={"linkarrow inline-flex items-center text-rose-600"}>
                                    <div className={"flex-none"}>Read more about how Maildrop works</div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="h-5 w-5">
                                        <path
                                            fillRule="evenodd"
                                            d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </Link>
                            </div>
                        </div>
                        <div
                            className={
                                "relative flex justify-center pt-24 pb-8 text-center md:flex-1 md:justify-start md:pt-4 lg:justify-end lg:justify-end"
                            }>
                            <SpecialOffer />
                        </div>
                    </div>
                </div>
            </section>
            <section className={"relative z-0 bg-stone-100 dark:bg-stone-900"}>
                <div className={"flex justify-center"}>
                    <img
                        src={logomark}
                        className={"w-12 opacity-5 dark:opacity-10 dark:invert"}
                        alt={"Maildrop logo"}
                    />
                </div>
                <div className={"relative px-8 pt-24 pb-32 md:container md:mx-auto lg:px-8"}>
                    <div className={"md:flex md:items-center"}>
                        <div
                            className={
                                "hidden opacity-80 dark:opacity-100 md:flex md:w-1/2 md:flex-none md:justify-center md:pr-8 lg:pr-16"
                            }>
                            <img
                                data-cypress={"phoneguy"}
                                src={phone}
                                className={"h-[500px] dark:contrast-[.85] md:mr-16"}
                                alt={"Man scrolling on his phone"}
                            />
                        </div>
                        <div data-cypress={"whymaildrop"} className={"md:w-1/2 md:flex-none"}>
                            <div className={"subhead"}>Why Maildrop?</div>
                            <div className={"pb-8 text-4xl font-bold"}>Maildrop is a great idea when you...</div>
                            <GreatIdea />
                        </div>
                    </div>
                </div>
            </section>
            <section
                className={
                    "relative z-0 bg-gradient-to-b from-stone-200 to-stone-50 dark:from-stone-800 dark:to-stone-900"
                }>
                <div className={"w-full overflow-hidden"}>
                    <div className={"h-16 w-full -translate-y-8 -rotate-2 scale-110 bg-stone-100 dark:bg-stone-900"}>
                        &nbsp;
                    </div>
                </div>
                <div className={"relative px-8 pt-32 pb-24 md:container md:mx-auto lg:px-8"}>
                    <div className={"md:flex md:justify-center"}>
                        <div className={"md:w-[50ch] md:flex-none lg:w-2/5"}>
                            <div className={"subhead"}>Antispam by Heluna</div>
                            <div className={"pb-4 text-4xl font-bold"}>Spam won't hit your Maildrop inbox, either.</div>
                            <p className={"pb-4 dark:text-stone-50/75 md:flex-1"}>
                                Maildrop is powered by some of the spam filters created by{" "}
                                <a
                                    href={"https://heluna.com/"}
                                    className={"text-rose-700 underline-offset-2 hover:underline dark:text-rose-500"}>
                                    Heluna
                                </a>
                                . The Heluna filters block almost all spam attempts before they even get to your
                                Maildrop inbox.
                            </p>
                            <p className={"pb-16 dark:text-stone-50/75 md:flex-1"}>
                                Even though Maildrop is a disposable inbox, you won't see a ton of spam messages when it
                                comes time to check your email.
                            </p>
                        </div>
                    </div>
                    <Statistics />
                </div>
            </section>
            <section className={"relative z-0"}>
                <div className={"flex justify-center"}>
                    <img
                        src={logomark}
                        className={"w-12 opacity-5 dark:opacity-10 dark:invert"}
                        alt={"Maildrop logo"}
                    />
                </div>
                <div className={"relative px-8 pt-24 pb-24 md:container md:mx-auto lg:px-8"}>
                    <div className={"md:flex md:items-center"}>
                        <div className={"pb-16 md:w-1/2 md:flex-none md:pr-8 md:pb-0 lg:pr-16"}>
                            <div className={"subhead"}>Maildrop for developers</div>
                            <div className={"pb-4 text-4xl font-bold"}>Email messages by API.</div>
                            <p className={"max-w-[50ch] pb-4 dark:text-stone-50/75"}>
                                Easy HTTP access to email messages? Yes, Maildrop has that too. With one simple GraphQL
                                interface, developers can incorporate Maildrop into their application.
                            </p>
                            <p className={"max-w-[50ch] pb-8 dark:text-stone-50/75"}>
                                Send test email messages to Maildrop inboxes instead of regular users. The Maildrop API
                                can help you automate retrieving and reading email messages.
                            </p>
                            <a
                                href="https://docs.maildrop.cc/"
                                className={"linkarrow inline-flex items-center text-rose-600"}>
                                <div className={"flex-none"}>Read Maildrop developer docs</div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5">
                                    <path
                                        fillRule="evenodd"
                                        d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </a>
                        </div>
                        <div className={"md:w-1/2 md:flex-none"}>
                            <CLI />
                        </div>
                    </div>
                </div>
            </section>
            <section
                className={
                    "relative z-0 bg-gradient-to-b from-stone-200 to-stone-50 dark:from-stone-800 dark:to-stone-900"
                }>
                <div className={"text-stone-50 dark:text-stone-900"}>
                    <svg
                        viewBox="0 0 960 100"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        className={"w-full"}
                        version="1.1">
                        <path
                            d="M0 54L40 49.7C80 45.3 160 36.7 240 36.5C320 36.3 400 44.7 480 50.2C560 55.7 640 58.3 720 56.2C800 54 880 47 920 43.5L960 40L960 0L920 0C880 0 800 0 720 0C640 0 560 0 480 0C400 0 320 0 240 0C160 0 80 0 40 0L0 0Z"
                            fill="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="miter"></path>
                    </svg>
                </div>
                <div className={"relative z-10 px-8 pt-24 pb-32 md:container md:mx-auto"}>
                    <div className={"md:flex md:items-center"}>
                        {/*<div className={"items-center justify-center md:flex md:flex-1"}>*/}
                        {/*    <div className={"w-full pb-16 md:mr-16 md:pb-0"}>*/}
                        {/*        <Banner slot={"2644897524"} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className={"flex flex-1 flex-col items-center"}>
                            <div
                                className={
                                    "bg-gradient-to-r from-pink-500 to-rose-500 bg-clip-text pb-8 text-center text-4xl font-bold text-transparent"
                                }>
                                Stop spam with Maildrop.
                            </div>
                            <div className={"w-full md:max-w-[40ch] lg:max-w-[50ch]"}>
                                <InboxForm size={"main"} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default IndexPage;

export const Head: HeadFC = () => (
    <>
        <title>Maildrop - Free Disposable Email Address</title>
        <meta
            name={"description"}
            content={
                "Maildrop provides free disposable e-mail addresses for use in web forms, app signups, or any other place you'd like to protect your privacy."
            }
        />
    </>
);
