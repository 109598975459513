import * as React from "react";
import { useInView } from "react-intersection-observer";
import { useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";

const GET_STATISTICS = gql`
    query GetStatistics {
        statistics {
            blocked
            saved
        }
    }
`;

interface Statistics {
    blocked: number;
    saved: number;
}

interface QueryReturn {
    statistics: Statistics;
}

const Statistics = () => {
    const [firstView, setFirstView] = useState(false);
    const [loadStatistics, { loading, error, data, startPolling, stopPolling }] = useLazyQuery<QueryReturn>(
        GET_STATISTICS,
        {}
    );
    const viewChanged = (inView: boolean) => {
        if (inView && !firstView) {
            setFirstView(true);
            void loadStatistics();
        }
        if (inView) {
            // We're in view, start loading the stats every five seconds
            startPolling(5000);
        } else {
            // We're out of view, stop loading the stats
            stopPolling();
        }
    };
    const { ref } = useInView({
        rootMargin: "100px 0px",
        fallbackInView: false,
        onChange: viewChanged
    });
    return (
        <div ref={ref} className={"md:flex md:justify-center"}>
            <div data-cypress={"statistics"} className={"md:mr-8 md:w-1/3 md:flex-none"}>
                <div
                    className={
                        "pb-2 text-center text-xs font-bold uppercase tracking-wider text-rose-600 dark:text-rose-600"
                    }>
                    <div className={"flex justify-center pb-2"}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-6 w-6">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                            />
                        </svg>
                    </div>
                    Messages blocked by Maildrop
                </div>
                <div className={"mb-8 h-9 max-h-9 overflow-hidden text-center font-bold md:pb-0"}>
                    {loading && (
                        <div
                            className={
                                "mt-2 inline-block h-6 w-12 w-[180px] animate-pulse rounded-full bg-gradient-to-r from-stone-300 to-stone-200 dark:from-stone-600 dark:to-stone-700"
                            }>
                            &nbsp;
                        </div>
                    )}
                    {data?.statistics.blocked && (
                        <div data-cypress={"blocked"} className={"text-3xl"}>
                            {data.statistics.blocked}
                        </div>
                    )}
                    {error && <div className={"text-rose-500"}>(error)</div>}
                </div>
            </div>
            <div className={"md:ml-8 md:w-1/3 md:flex-none"}>
                <div
                    className={
                        "pb-2 text-center text-xs font-bold uppercase tracking-wider text-green-600 dark:text-lime-600"
                    }>
                    <div className={"flex justify-center pb-2"}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-6 w-6">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>
                    Messages saved to Maildrop
                </div>
                <div className={"h-9 max-h-9 overflow-hidden text-center font-bold"}>
                    {loading && (
                        <div
                            className={
                                "mt-2 inline-block h-6 w-12 w-[180px] animate-pulse rounded-full bg-gradient-to-r from-stone-300 to-stone-200 dark:from-stone-600 dark:to-stone-700"
                            }>
                            &nbsp;
                        </div>
                    )}
                    {data?.statistics.saved && <div className={"text-3xl"}>{data.statistics.saved}</div>}
                    {error && <div className={"text-rose-500"}>(error)</div>}
                </div>
            </div>
        </div>
    );
};

export default Statistics;
