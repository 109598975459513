import * as React from "react";
import Rail from "./rail";

const GreatIdea = () => (
    <Rail
        cards={[
            {
                text: "Want to sign up for a website but you're concerned that they might share your address with advertisers. 😬",
                bgfrom: "from-blue-500",
                bgto: "to-rose-500"
            },
            {
                text: "Publish your email address in a place that could be picked up by address-harvesting bots. 🤖",
                bgfrom: "from-rose-500",
                bgto: "to-slate-500"
            },
            {
                text: "Are required to provide an email address to a mobile app that shouldn't be sending you messages. 📱",
                bgfrom: "from-slate-500",
                bgto: "to-slate-300"
            },
            {
                text: "Give your address to companies that have a track record of less-than-stellar security. 👿",
                bgfrom: "from-slate-300",
                bgto: "to-purple-500"
            },
            {
                text: 'Are making a one-off purchase from an ecommerce site where you don\'t want followup spam about their "latest deals" in your inbox. 🛍',
                bgfrom: "from-purple-500",
                bgto: "to-orange-500"
            },
            {
                text: "Need to receive exactly one email from a sender and then you can completely ignore every other email afterwards. 🤐",
                bgfrom: "from-orange-500",
                bgto: "to-stone-700"
            },
            {
                text: "Want to just try out an internet service that requires you to verify your email address before proceeding. 🙄",
                bgfrom: "from-stone-700",
                bgto: "to-stone-200"
            },
            {
                text: "As a developer, you want to automatically test the email sending capabilities of your own web application before launching it to users. 💻",
                bgfrom: "from-stone-200",
                bgto: "to-cyan-500"
            },
            {
                text: "Create mock test data for users in your own database and you don't want to have real email addresses included in the test data. 👻",
                bgfrom: "from-cyan-500",
                bgto: "to-pink-500"
            },
            {
                text: "Just don't want to give out your real email address to anyone, for any reason, at any time. Maildrop will help you remain private. 😍",
                bgfrom: "from-pink-500",
                bgto: "to-rose-500"
            }
        ]}
    />
);

export default GreatIdea;
