import * as React from "react";
import { useEffect, useState } from "react";

const CLI = () => {
    const APOLLO_URI = process.env.GATSBY_APOLLO_URI ?? "https://localhost/graphql";
    const textToCopy = `curl --request POST \\\n   --header 'content-type: application/json' \\\n   --url ${APOLLO_URI} \\\n   --data '{ "query": "query Example { ping(message: \\"hello\\") inbox(mailbox: \\"testing\\") { id mailfrom subject } }" }'`;
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        if (!copied) {
            return;
        }
        const timeout = setTimeout(() => setCopied(false), 2000);
        return () => clearTimeout(timeout);
    }, [copied]);
    const copyClick = (evt: React.MouseEvent<HTMLDivElement>) => {
        evt.preventDefault();
        void navigator.clipboard.writeText(textToCopy);
        setCopied(true);
    };
    return (
        <div
            className={
                "relative mt-8 overflow-hidden rounded-xl bg-gradient-to-b from-slate-800 to-slate-700 text-stone-200 dark:text-stone-50/75"
            }>
            <div className={"h-8 border-b-[1px] border-slate-600"}>
                <div className={"mt-3 ml-4 inline-block h-2.5 w-2.5 rounded-full bg-slate-600"}>&nbsp;</div>
                <div className={"mt-3 ml-2 inline-block h-2.5 w-2.5 rounded-full bg-slate-600"}>&nbsp;</div>
                <div className={"mt-3 ml-2 inline-block h-2.5 w-2.5 rounded-full bg-slate-600"}>&nbsp;</div>
            </div>
            <div className={"whitespace-nowrap p-4 font-terminal text-terminal lg:whitespace-normal"}>
                <span className={"text-lime-400 dark:text-lime-400/75"}>you@yourmachine</span>
                <span className={"text-stone-200 dark:text-stone-50/75"}>:</span>
                <span className={"text-blue-500 dark:text-blue-500/75"}>~</span>
                <span className={"mr-2 text-stone-200 dark:text-stone-50/75"}>$</span>
                <span>curl --request POST \</span>
                <div>
                    <span className={"hidden lg:inline"}>&nbsp;&nbsp;&nbsp;</span>--header 'content-type:
                    application/json' \
                </div>
                <div>
                    <span className={"hidden lg:inline"}>&nbsp;&nbsp;&nbsp;</span>--url {APOLLO_URI} \
                </div>
                <div>
                    <span className={"hidden lg:inline"}>&nbsp;&nbsp;&nbsp;</span>--data '&#123; "query": "query Example
                    &#123; ping(message: \"hello\") inbox(mailbox: \"testing\") &#123; id mailfrom subject &#125;
                    &#125;" &#125;'
                </div>
                <div className={"mt-4"}>
                    &#123;"data":&#123;"ping":"pong
                    hello","inbox":[&#123;"id":"abcdef","mailfrom":"hello@maildrop.cc","subject":"Welcome to
                    Maildrop!"&#125;]&#125;&#125;
                </div>
                <div className={"mt-4 mb-16"}>
                    <span className={"text-lime-400 dark:text-lime-400/75"}>you@yourmachine</span>
                    <span className={"text-stone-200 dark:text-stone-50/75"}>:</span>
                    <span className={"text-blue-500 dark:text-blue-500/75"}>~</span>
                    <span className={"mr-2 text-stone-200 dark:text-stone-50/75"}>$</span>
                    <span className={"w-2 bg-stone-200"}>&nbsp;</span>
                </div>
            </div>
            <div className={"group absolute bottom-0 right-0 p-4 hover:cursor-pointer"} onClick={copyClick}>
                <div className={"flex"}>
                    <div className={"group-hover:text-pink-500"}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-6 w-6">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                            />
                        </svg>
                    </div>
                    <div className={"flex h-6 flex-col items-center overflow-hidden pl-2"}>
                        <div
                            className={`${
                                copied ? "-translate-y-10" : "-translate-y-0"
                            } transition-transform group-hover:bg-gradient-to-r group-hover:from-pink-500 group-hover:to-rose-500 group-hover:bg-clip-text group-hover:text-transparent`}>
                            Copy request
                        </div>
                        <div className={`pt-4 ${copied ? "-translate-y-10" : "-translate-y-0"} transition-transform`}>
                            Copied!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CLI;
