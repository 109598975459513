import * as React from "react";
import logomark from "../../images/logomark.svg";

interface Card {
    text: string;
    bgfrom: string;
    bgto: string;
}

interface RailProps {
    cards: Card[];
}

const Rail = ({ cards }: RailProps) => {
    return (
        <div className={"relative flex w-full snap-x flex-nowrap items-stretch overflow-x-auto scroll-smooth pb-8"}>
            {cards.map(({ text, bgfrom, bgto }, idx: number) => (
                <div
                    key={`rail.${idx}.${text.substring(0, 5)}`}
                    className={
                        "mr-8 mt-10 w-64 flex-none snap-start rounded-lg bg-white shadow-sm last:mr-0 dark:bg-stone-800"
                    }>
                    <div
                        className={`relative flex h-4 justify-center rounded-t-lg bg-gradient-to-r ${
                            bgfrom ? bgfrom : "from-pink-500"
                        } ${bgto ? bgto : "to-rose-500"} text-center`}>
                        <div
                            className={
                                "absolute -top-10 rounded-full border-2 border-white bg-stone-100 p-4 text-rose-500 dark:border-stone-900 dark:bg-stone-800"
                            }>
                            <img
                                src={logomark}
                                className={"h-10 w-10 -translate-y-[2px] opacity-70 dark:invert"}
                                alt={"Maildrop logo"}
                            />
                        </div>
                    </div>
                    <div className={"px-8 pt-12 pb-12 dark:text-stone-50/75"}>{text}</div>
                </div>
            ))}
        </div>
    );
};

export default Rail;
