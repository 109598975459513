import * as React from "react";
import { useInView } from "react-intersection-observer";
import specialoffer from "../../images/specialoffer.png";
import { SuggestionContext } from "../context";

const SpecialOffer = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: "-100px 0px",
        fallbackInView: true
    });
    return (
        <SuggestionContext.Consumer>
            {({ suggestion }) => (
                <div
                    ref={ref}
                    className={`specialOfferImage relative mt-2 transition duration-700 ease-in-out ${
                        inView
                            ? "translate-y-0 scale-100 opacity-90 dark:opacity-80"
                            : "translate-y-24 scale-75 opacity-0"
                    }`}>
                    <img
                        src={specialoffer}
                        width={450}
                        height={280}
                        alt={"Popup special offer modal to demonstrate Maildrop"}
                        className={"max-w-[480px] rounded-2xl"}
                    />
                    <div className={"absolute top-[160px] left-[36px] h-[40px] w-[380px] bg-stone-50"}>&nbsp;</div>
                    <div className={"absolute top-[168px] left-[44px]"}>
                        <div className={"specialOfferEmail dark:text-stone-900"}>{suggestion}@maildrop.cc</div>
                    </div>
                    <div
                        className={
                            "absolute top-[22px] left-[30px] -rotate-45 text-sm font-bold uppercase text-stone-50 md:left-0"
                        }>
                        Example
                    </div>
                </div>
            )}
        </SuggestionContext.Consumer>
    );
};

export default SpecialOffer;
